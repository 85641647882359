import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';
import { Box } from '@thepuzzlers/pieces';

// Local Components
import {
  useLandingPageData,
  Header,
  Features,
  FeatureCard,
  Empower
} from 'sections/landingPage';
import { Spacer } from 'components';

// Reused Section
import { Cta } from 'components/section';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';

// Data
import { useKisLandingPageData } from 'graphqlHooks';

const Home = () => {
  const { seo, header, features } = useLandingPageData();
  const {
    header: cmsHeader,
    features: cmsFeatures,
    feature_card: cmsFeatureCard,
    empower: cmsEmpower
  } = useKisLandingPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Header staticData={header} data={cmsHeader} />
      <Box as="main">
        <Features staticData={features} data={cmsFeatures} />
        <FeatureCard data={cmsFeatureCard} />
        <Spacer
          height={['24.7rem', null, '9rem', null, '18.4rem', '21.2rem']}
        />
        <Empower data={cmsEmpower} />
        <Spacer
          height={['23.1rem', null, '30.2rem', null, '44.6rem', '37.8rem']}
        />
        <Cta />
        <Spacer height={['8rem', null, '8rem', null, '6.3rem', '7.1rem']} />
      </Box>
    </>
  );
};

export default injectIntl(Home);
